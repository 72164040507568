@import './variables.scss';
.btn:focus {
    box-shadow: none !important;
}

.btn-primary {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
    color: $white-color;
}

.btn-primary:hover {
    background-color: $primary-color-hover !important;
    border-color: $primary-color-hover !important;
    color: $white-color;
}

.btn-primary:active {
    background-color: $primary-color-active !important;
    border-color: $primary-color-active !important;
    color: $white-color;
}

.btn-primary:disabled {
    background-color: $primary-color-disabled !important;
    border-color: $primary-color-disabled !important;
    color: $white-color;
}

.btn-outline-primary {
    color: $primary-color !important;
    border-color: $primary-color !important;
}

.btn-outline-primary:hover {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
    color: $white-color !important;
}

.btn-outline-primary:active {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
    color: $white-color;
}

.btn-outline-primary:disabled {
    color: $primary-color-disabled !important;
    border-color: $primary-color-disabled !important;
}

.btn-link-light {
    color: $white-color;
}

.btn-link-light:hover {
    color: $white-color;
    font-weight: bold;
}

.calendar-btn {
    border: none;
    background: #cbe2e6;
    color: #008b92;
}

.calendar-btn:hover,
.calendar-btn:active {
    border: none;
    background: #008b92;
    color: white;
}

.calendar-btn:focus {
    box-shadow: none;
}

.btn-overhaul {
    background-color: #f8e8b6;
    border-color: #f8e8b6;
}

.btn-overhaul:hover {
    background-color: #eccb68;
    border-color: #eccb68;
}

.btn-hsi{
    background-color: #f8d1b6;
    border-color: #f8d1b6;
}
.btn-hsi:hover {
    background-color: #f3c29f;
    border-color: #f3c29f;
}