@import 'variables.scss';
.navbar {
    padding: 0rem 90px;
    background-color: $primary-color;
}

.navtabs {
    margin-left: 75px;
    margin-bottom: -17px;
    .nav-item {
        padding: 0px 20px;
        height: 55px;
        cursor: pointer;
        .nav-link {
            //margin-top: 10px;
        }
    }
}

.navbar-brand {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    img {
        width: 160px;
    }
}

.nav-item.active {
    background: white;
    border-radius: 10px 10px 0px 0px;
    .nav-link {
        color: $primary-color !important;
        font-weight: bold;
    }
}

.subnav {
    background-color: white;
    padding: 15px 90px;
    margin-bottom: 25px;
    margin-top: 65px;
}

.subnav-pager {
    margin: 0;
    padding: 0;
}

.bread-text {
    font-size: 1.1rem;
}

.tb-link {
    color: $label-color;
    padding: 10px 15px;
    border-radius: 10px;
    font-size: medium;
    cursor: pointer;
}

.tb-link.active {
    background-color: $input-bg-color;
    color: $primary-color;
    font-weight: bold;
}

.tb-link.active:hover {
    background-color: $input-bg-color;
    color: $primary-color !important;
    font-weight: bold;
}

.tb-link:hover {
    background-color: $input-bg-color;
    text-decoration: none !important;
    color: $label-color !important;
}