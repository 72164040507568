.form-control {
    background-color: $input-bg-color;
    border: none;
}

.input-group-text {
    background-color: $input-bg-color;
    border: none;
    i {
        color: $label-color;
    }
}

.form-control:focus {
    box-shadow: none;
    background-color: $input-bg-color;
}