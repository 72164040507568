@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;800&display=swap');
@font-face {
    font-family: 'Inter', sans-serif;
    font-display: block;
    font-style: normal;
}

.color-primary {
    color: $primary-color !important;
}

.color-secondary {
    color: $secondary-color !important;
}

.font-weight-bolder {
    font-weight: 900;
}