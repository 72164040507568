$main-bg-color: #E8E8E8;
$app-bg-color: #f5f5f6;
$input-bg-color: #F3F6F9;
$label-color: #6E7191;
$dark-bg-color: #0b1a37;
$main-text-color: #212121;
$main-footer-bg: #f9f9fa;
$card-bg-color: #ffffff;
$border-color: #D1D1D1;
$banner-bg: #F2E8FC;
$secondary-color: #1CC8EE;
$aqua-color: #68D3CD;
$info-color: #5b9faa;
$white-color: #ffffff;
$danger-color: #F7504A;
$warning-color: #FFC107;
$success-color: #2E8500;
$primary-color: #008B92;
$primary-color-hover: #00565D;
$primary-color-active: #00565D;
$primary-color-disabled: #E6F4F1;